import { createRouter, createWebHistory, NavigationFailure, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import CalibrationVehicle from "@/views/CalibrationVehicle.vue";
import CalibrationContacting from "@/views/CalibrationContacting.vue";
import CalibrationVin from "@/views/CalibrationVin.vue";
import CalibrationRideHeight from "@/views/CalibrationRideHeight.vue";
import CalibrationCameraSetup from "@/views/CalibrationCameraSetup.vue";
import CalibrationCameraReady from "@/views/CalibrationCameraReady.vue";
import CalibrationCameraImage from "@/views/CalibrationCameraImage.vue";
import CalibrationSendTargets from "@/views/CalibrationSendTargets.vue";
import CalibrationMeasuredWindshieldAngle from "@/views/CalibrationMeasuredWindshieldAngle.vue";
import CalibrationNewWindshieldAngle from "@/views/CalibrationNewWindshieldAngle.vue";
import CalibrationInProgress from "@/views/CalibrationInProgress.vue";
import CalibrationContactSupportWindshieldAngle  from "@/views/CalibrationContactSupportWindshieldAngle.vue";
import CalibrationUnverifiedVehicle  from "@/views/CalibrationUnverifiedVehicle.vue";

import CalibrationComplete from "@/views/CalibrationComplete.vue";
import CalibrationFailed from "@/views/CalibrationFailed.vue";
import UpgradeRequired from "@/views/UpgradeRequired.vue";
import UpgradeDownload from "@/views/UpgradeDownload.vue";
import UpgradeInstalling from "@/views/UpgradeInstalling.vue";
import UpgradeInstalled from "@/views/UpgradeInstalled.vue";
import UpgradeFailed from "@/views/UpgradeFailed.vue";
import { SignalR } from "./support/SignalR";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/Calibration",
        name: "CalibrationVehicle",
        component: CalibrationVehicle
    },
    {
        path: "/Calibration/Contacting",
        name: "CalibrationContacting",
        component: CalibrationContacting
    },
    {
        path: "/Calibration/UnverifiedVehicle",
        name: "CalibrationUnverifiedVehicle",
        component: CalibrationUnverifiedVehicle
    },
    {
        path: "/Calibration/Vin",
        name: "CalibrationVin",
        component: CalibrationVin
    },
    {
        path: "/Calibration/RideHeight",
        name: "CalibrationRideHeight",
        component: CalibrationRideHeight
    },
    {
        path: "/Calibration/CameraSetup",
        name: "CalibrationCameraSetup",
        component: CalibrationCameraSetup
    },
    {
        path: "/Calibration/CameraReady",
        name: "CalibrationCameraReady",
        component: CalibrationCameraReady
    },
    {
        path: "/Calibration/CameraImage",
        name: "CalibrationCameraImage",
        component: CalibrationCameraImage
    },
    {
        path: "/Calibration/SendTargets",
        name: "CalibrationSendTargets",
        component: CalibrationSendTargets
    },
    {
        path: "/Calibration/MeasuredWindshieldAngle",
        name: "CalibrationMeasuredWindshieldAngle",
        component: CalibrationMeasuredWindshieldAngle
    },
    {
        path: "/Calibration/NewWindshieldAngle",
        name: "CalibrationNewWindshieldAngle",
        component: CalibrationNewWindshieldAngle
    },
    {
        path: "/Calibration/ContactSupportWindshieldAngle",
        name: "CalibrationContactSupportWindshieldAngle",
        component: CalibrationContactSupportWindshieldAngle
    },
    {
        path: "/Calibration/InProgress",
        name: "CalibrationInProgress",
        component: CalibrationInProgress
    },

    {
        path: "/Calibration/Complete",
        name: "CalibrationComplete",
        component: CalibrationComplete
    },
    {
        path: "/Calibration/Failed",
        name: "CalibrationFailed",
        component: CalibrationFailed
    },

    {
        path: "/Upgrade/Required",
        name: "UpgradeRequired",
        component: UpgradeRequired
    },
    {
        path: "/Upgrade/Download",
        name: "UpgradeDownload",
        component: UpgradeDownload
    },
    {
        path: "/Upgrade/Installing",
        name: "UpgradeInstalling",
        component: UpgradeInstalling
    },
    {
        path: "/Upgrade/Installed",
        name: "UpgradeInstalled",
        component: UpgradeInstalled
    },
    {
        path: "/Upgrade/Failed",
        name: "UpgradeFailed",
        component: UpgradeFailed
    },

    {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: Home,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.afterEach( (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void): any => {
    if (failure) return;
    if (!SignalR.isConnected) return;
    SignalR.connection.send("PageNavigation", to.path);
});

export default router;
