import { CalibrationType } from "@/shared/enums/CalibrationType";
import { SessionType } from "@/shared/enums/SessionType";
import { AUGGIEAVAILABLE } from "@/main";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import CameraViewerConfiguration from "@/shared/models/CameraViewerConfiguration";
import { PackageDto } from "@/shared/models/PackageDto";
import { VehicleDto } from "@/shared/models/VehicleDto";
import { MarkerType } from "@/shared/enums/MarkerType";

export class GlobalData {

    public MainApp: any = null!;
    public MainHome: any = null!;
    public DeviceSerialNumber: string = "";
    public Environment: string = "PRD";
    public PackageUpgrade: PackageDto | null = null;

    public GetWebAPIUrl(): string {
        let url: string;
        switch (this.Environment) {
            case "DEV":
                url = process.env.VUE_APP_WEBAPI_DEV!;
                break;
            case "QAT":
                url = process.env.VUE_APP_WEBAPI_QAT!;
                break;
            case "UAT":
                url = process.env.VUE_APP_WEBAPI_UAT!;
                break;
            case "STG":
                url = process.env.VUE_APP_WEBAPI_STG!;
                break;
            default:
                url = process.env.VUE_APP_WEBAPI_PRD!;
                break;
        }
        if (!url.endsWith("/"))
            url = url += "/";
        return url;
    }
    public GetCompanionUrl(): string {
        let url: string;
        switch (this.Environment) {
            case "DEV":
                url = process.env.VUE_APP_COMPANION_DEV!;
                break;
            case "QAT":
                url = process.env.VUE_APP_COMPANION_QAT!;
                break;
            case "UAT":
                url = process.env.VUE_APP_COMPANION_UAT!;
                break;
            case "STG":
                url = process.env.VUE_APP_COMPANION_STG!;
                break;
            default:
                url = process.env.VUE_APP_COMPANION_PRD!;
                break;
        }
        if (!url.endsWith("/"))
            url = url += "/";
        return url;
    }
    public GetSignalRFunctionUrl(): string {
        switch (this.Environment) {
            case "DEV":
                return process.env.VUE_APP_SignalRFunction_DEV!;
            case "QAT":
                return process.env.VUE_APP_SignalRFunction_QAT!;
            case "UAT":
                return process.env.VUE_APP_SignalRFunction_UAT!;
            case "STG":
                return process.env.VUE_APP_SignalRFunction_STG!;
            default:
                return process.env.VUE_APP_SignalRFunction_PRD!;
        }
    }
    public UseFakeCameraImage(): boolean {
        if (AUGGIEAVAILABLE) {
            return Auggie.System.PredefinedCameraImage();
        } else {
            return process.env.VUE_APP_FAKECAMERAIMAGE === "1";
        }
    }
    public IgnoreCameraCalibration(): boolean {
        if (AUGGIEAVAILABLE) {
            return Auggie.System.IgnoreCameraCalibration();
        } else {
            console.log(`VUE_APP_IGNORECAMERACALIBRATION = ${process.env.VUE_APP_IGNORECAMERACALIBRATION}`);
            return process.env.VUE_APP_IGNORECAMERACALIBRATION === "1";
        }
    }
    public get DetailLog(): boolean {
        if (this.DetailLogCache != null) return this.DetailLogCache;
        if (!AUGGIEAVAILABLE || !Global.IsVersionAtLeast("2023.019.12")) {
            this.DetailLogCache = false;
            return false;
        }
        this.DetailLogCache = Auggie.System.GetDetailLog();
        return this.DetailLogCache || false;
    }
    public set DetailLog(value: boolean) {
        if (!value) return; // we can only turn it on
        if (this.DetailLogCache != null && this.DetailLogCache) return; // already on
        if (!AUGGIEAVAILABLE || !Global.IsVersionAtLeast("2023.019.12")) return;
        Auggie.System.SetDetailLog(true);
        this.DetailLogCache = true;
    }

    /**
     * Check whether the installed device client has at least the specified version.
     */
    public IsVersionAtLeast(reqVersion: string): boolean {
        if (!AUGGIEAVAILABLE) return true;
        const deviceVersion = Auggie.System.Version;

        const devParts = deviceVersion.split(".");
        if (devParts.length !== 3)
            return false;
        const deviceVersionMajor = Number(devParts[0]);
        const deviceVersionMinor = Number(devParts[1]);
        const deviceVersionBuild = Number(devParts[2]);

        const reqParts = reqVersion.split(".");
        if (reqParts.length !== 3)
            return false;
        const reqMajor = Number(reqParts[0]);
        const reqMinor = Number(reqParts[1]);
        const reqBuild = Number(reqParts[2]);

        if (reqMajor > deviceVersionMajor) return false;
        if (reqMajor < deviceVersionMajor) return true;
        if (reqMinor > deviceVersionMinor) return false;
        if (reqMinor < deviceVersionMinor) return true;
        if (reqBuild > deviceVersionBuild) return false;
        if (reqBuild < deviceVersionBuild) return true;
        return true;
    }
    public IsVersionBeyondLastSupported(): boolean {
        if (!AUGGIEAVAILABLE) return false;
        const deviceVersion = Auggie.System.Version;

        // The last supported device client version is set by the build pipeline (is overridden in .env file).
        // VUE_APP_LASTSUPPORTEDDEVICEVERSION is set to the current build number in the build pipeline.
        const lastVersion = process.env.VUE_APP_LASTSUPPORTEDDEVICEVERSION || "";
        if (!lastVersion) return false;

        const devParts = deviceVersion.split(".");
        if (devParts.length !== 3)
            return true;
        const deviceVersionMajor = Number(devParts[0]);
        const deviceVersionMinor = Number(devParts[1]);
        const deviceVersionBuild = Number(devParts[2]);

        const lastParts = lastVersion.split(".");
        if (lastParts.length !== 3)
            return true;
        const lastMajor = Number(lastParts[0]);
        const lastMinor = Number(lastParts[1]);
        const lastBuild = Number(lastParts[2]);

        if (lastMajor > deviceVersionMajor) return false;
        if (lastMajor < deviceVersionMajor) return true;
        if (lastMinor > deviceVersionMinor) return false;
        if (lastMinor < deviceVersionMinor) return true;
        if (lastBuild > deviceVersionBuild) return false;
        if (lastBuild < deviceVersionBuild) return true;
        return false;
    }

    public IsPrototype(): boolean {
        if (!AUGGIEAVAILABLE || !Global.IsVersionAtLeast("2023.186.1")) return false;
        return Auggie.Device.Prototype();
    }

    public IsDemo(): boolean {
        if (!AUGGIEAVAILABLE || !Global.IsVersionAtLeast("2023.195.1")) return false;
        return Auggie.Device.Demo();
    }

    public DeviceGuid: string = "";
    public DeviceName: string = "";
    public AllowAssistedCalibration: boolean = false;
    public AllowAssistedTargetDisplay: boolean = false;
    public AllowSelfCalibration: boolean = false;
    public DetailLogCache: boolean|null = null;
    public VerifiedVehiclesOnlyInd: boolean = true;

    // Session information

    public CalibrationSession: CalibrationSessionDto|null = null;
    public CameraViewerConfiguration: CameraViewerConfiguration|null = null;
    public Make: string = "";
    public Model: string = "";
    public Year: number = 0;
    public Calibration: CalibrationType = CalibrationType.All;
    public LegLengthA: number = 0;
    public LegLengthB: number = 0;
    public LegLengthC: number = 0;
    public Marker: MarkerType = MarkerType.Standard;
    public WheelArchHeightMin: number|null = null;
    public WheelArchHeightMax: number|null = null;
    public TechnicianName: string = "";
    public VehicleId: number|null = null;
    public SessionType: SessionType|null = null;
    public IsTechControlled: boolean = false;
    public LightIntensity: number = 50;
    public OriginalWindshieldAngle: number|null = null;
    public DemoCameraImage: string = "";
    public DemoVehicle: VehicleDto|null = null;

    public clear(): void {
        this.CalibrationSession = null;
        this.CameraViewerConfiguration = null;
        this.Make = "";
        this.Model = "";
        this.Year = 0;
        this.Calibration = CalibrationType.All;
        this.LegLengthA = 0;
        this.LegLengthB = 0;
        this.LegLengthC = 0;
        this.Marker = MarkerType.Standard;
        this.WheelArchHeightMin = null;
        this.WheelArchHeightMax = null;
        this.TechnicianName = "";
        this.VehicleId = null;
        this.SessionType = null;
        this.IsTechControlled = false;
        this.OriginalWindshieldAngle = null;
        this.DemoCameraImage = "";
    }
}
export const Global = new GlobalData();