

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { SignalR } from "@/support/SignalR";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { Global } from "@/support/GlobalData";
import { SessionType } from "@/shared/enums/SessionType";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import { confirmOk } from "@/shared/components/common/AlertDialog.vue";
import { DataRequest } from "@/shared/support/Data";
import { CalibrationType } from "@/shared/enums/CalibrationType";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationUnverifiedVehicle extends PageBase {

    nextClicked(): void {
        this.startSession();
    }

    async startSession(): Promise<void> {

        this.loading = true;

        let session: CalibrationSessionDto|null = null;
        try {
            const dr = new DataRequest();
            dr.autoToastOnFailure = false;
            const calibrationSession = { CalibrationSessionGuid: Global.CalibrationSession!.CalibrationSessionGuid,
                VehicleId: Global.VehicleId,
                DeviceGuid: Global.DeviceGuid,
                CalibrationType: Global.Calibration,
            } as CalibrationSessionDto;
            session = await dr.$post<CalibrationSessionDto, CalibrationSessionDto>("/Service/CalibrationSession", null, calibrationSession);
            if (session)
                session.CalibrationType = Global.Calibration;
        } catch (reason) {
            confirmOk(`Unable to establish a session (${reason})`);
        }

        this.loading = false;

        if (!session || !session.UpdateResult.Success) {
            confirmOk(session?.UpdateResult.Message || "The server was unable to establish a session");
            return;
        }
        Global.MainApp.setSession(session);
        if (Global.IsTechControlled) {
            this.$router.push("/Calibration/CameraSetup");
        } else if (Global.SessionType === SessionType.AssistedCalibration || Global.SessionType === SessionType.AssistedTargetDisplay) {
            this.$router.push("/Calibration/RideHeight");
        } else {
            this.$router.push("/Calibration/Vin");
        }
    }

    cancelClicked(): void {
        this.$router.push("/Calibration");
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationUnverifiedVehicle",
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onUnverifiedVehicleContinueByCompanion = this.nextClicked;
        SignalR.onUnverifiedVehicleBackByCompanion = this.cancelClicked;
        SignalR.onTechnicianConnectedSession = (techName: string): void => {
            this.$router.push("/Calibration");
        };
        this.getCurrentStatusForListeners();
    }

    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onUnverifiedVehicleContinueByCompanion = null;
        SignalR.onUnverifiedVehicleBackByCompanion = null;
        SignalR.onTechnicianConnectedSession = null;
    }
}
